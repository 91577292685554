/* Clear some defaults */
a,a:hover,a:active,a:link,a:visited {text-decoration: none;color:inherit;outline:0}
*:focus {
    outline: none;
}

input[type="submit"],
input[type="submit"]:hover,
input[type="submit"]:focus,
input[type="submit"]:active {
    outline: none;
    border:none;
    background: none;
    background-color: clear;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration,
textarea, input, img, button, input[type="submit"], input[type="reset"] {
  -webkit-appearance:none;
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

*:not(input):not(textarea) {
  user-select:none !important;
  -webkit-user-select: none !important;
  -webkit-touch-callout: none !important;
  -khtml-user-select:none !important;
  -moz-user-select:none !important;
  -ms-user-select:none !important;}

/* GLOBAL */
html *
{
   font-size: 3.73vw !important; /* 14px */
   color: white;
   font-family: Poppins !important;
   font-style: normal;
}
h1{ /* ViewsActivityCel titles */
    font-family: Montserrat !important;
    font-style: semibold !important;
    font-size:7.47vw !important; /* 28px */
    padding:0px !important;
    margin:0px !important;
}
.h1min{ /* e.g. Share movie? */
  font-family: Montserrat !important;
  font-style: semibold !important;
  font-size:6.4vw !important; /* 24px */
}
h2{ /* 14px, 16px, 28px */
  font-weight: 600 !important;
  font-family: Montserrat !important;
  font-size:4.27vw; /* 16px */
  padding:0px !important;
  margin:0px !important;
}
h3{
  font-family: Poppins !important;
  font-size:3.73vw;
}

/* GENERIC */
.transparent{
  background-color: transparent !important;
  border: 0 !important;
}
.greenColor{
  color: #96E199 !important;
}
.purpleColor{
  color:#735FF3 !important;
}
.redColor{
  color:#E85743 !important;
}
.noMargin{
  padding: 0 !important;
  margin: 0 !important;
}
.noMarginLeft{
  margin-left: 0 !important;
  padding-left: 0 !important;
}
.marginAuto{
  margin:auto !important;
}
.noScroll { 
  overflow: hidden;
}
.noSelect{
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.hidden{
  display: none !important;
}
.centered{
  
  margin:auto;
  position:relative;
  display: flex;
  align-items: center;
  justify-content: center;

}
.centeredAbsolute{
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
}
.alignCenter{
  text-align: center;
}
.positionAbsolute{
  position: absolute !important;
}
.overflowHidden{
  overflow:hidden !important;
}
.inlineBlock{
  display: inline-block !important;
}
.inline{
  display: inline !important;
}
.block{
  display: block !important;
}
.verticalMiddle{
  display:flex !important;
  align-items: center;
}
.imageCenter{
  vertical-align: middle;
}
.verticalTop{
  vertical-align: top;
}
.grayText{
  opacity: 0.4;
}
.textCenter{
  text-align: center;
}
.col{
  display: inline-block !important;
  vertical-align: top !important;
}
.row{

}
.floatRight{
  float:right;
}
.flexDisplay{
  display:flex !important;
}
.flexRow{
  display:flex !important;
  flex-direction: row;
  flex-wrap:wrap;
}
.flexCol{
  display:flex !important;
  flex-direction: column;
  flex-wrap:wrap;
}
.flexFullWidth{
  flex-basis:100%;
}
.fullWidth{
  width:100%;
}
.frontView{
  z-index: 100;
}

/* Separation */
.SeparationLine{
    width: 33.8vw;
    height: 0px;
    border: 1px solid #FFFFFF;
    margin-top:2vw;
}
.SeparationOr{
    text-align: center;
    width: 22.8vw;
    padding-bottom:12vw;
}
.SeparationFullWidth{
    width:100%;
    height:0.2667vw;
    opacity:0.1;
    background: #FFFFFF;
    margin-top:4.267vw;
    margin-bottom:4.267vw;
}
/* MENU */
/* Tabs */
.MenuTabContainer{
  margin-left:4.21vw;
  margin-right:4.21vw;
}
.MenuTabButton{
  display:inline-block;
  text-align:center;
  padding-bottom: 2.67vw;
  padding-top:2.67vw;
  font-size: 4.27vw;
}
.MenuTabButtonSelected{
  border-bottom: 0.467vw solid #497BE1;

}
/* Top */
.MenuTop{
    background-color: transparent !important;
    width:100% !important;
    height:18.73vw;
    padding-top:3.867vw;
}
.LogoTop{
    padding-left:4.267vw;
    display:inline-block;
    margin-top:-2.vw;
}
.ButtonTop{
  padding-left:4.267vw;
  padding-top:4.267vw;
  right:0;
  position:absolute;
}

.MenuBottom{
    background-color: rgba(37, 37, 65, 0.24);
    backdrop-filter: blur(32px);
    width:100vw !important;
    height:18.93vw !important;
    left:0 !important;
    bottom:0 !important;
    position:fixed !important;
    margin:0;
}
.MenuBottomContainer{
  margin-left:4.26vw;
  width:91.48vw;
  display:inline-block;
  position:relative;
  height:100%;
  text-align:center;
}
.MenuBottomCell{
    height:11.2vw !important;
    margin:0 !important;
    padding:0 !important;
    width:20vw !important;
    height:100% !important;
    display:inline-block;
    
    text-align: center;
    top:1.6vw;
    position:relative;

}
.MenuBottomImageContainer{
    width:7.467vw;
    height:7.467vw;
    text-align: center;
    position:relative;
    display: inline-block;
}
.MenuBottomText{
    font-size:0.8em !important;
}

/* IMAGES */
/* Logo */
.TopLogo{
  width: 40vw;
  pointer-events: none;
}

/* Avatar */
.AvatarImage{
  margin: 0 !important;
  padding: 0 !important;
}
.AvatarImageContainerCircleImage{
  /* added to container */
  border-radius: 100% !important;
  overflow:hidden !important;
  display:inline-block !important;
  
}

/*
.AvatarImageContainerBlob{

}
*/

/* Menu */
.MenuTopImage{

  padding: 2.13vw; /* between icon and button border */

  background: rgba(255, 255, 255, 1);
  border-radius: 3.2vw !important;

}

/* Swipe */
.SwipeCardImage{
  width:100%;
  height:100%;
  position:relative;
  top:0;
  left:0;
  overflow:hidden !important;
  border-radius: 9.56%/6% !important;
  filter:brightness(90%);

  pointer-events:none;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.FilterButtonPlatform{
  position:absolute;
  right:1.25vw;
  top:1.25vw;
  pointer-events: none;
}

/* Blobs */
.BackgroundBlobTopLeft{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -100000;
    width:100vw;
}
.BackgroundBlobBottomLeft{
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -10;
  width:100vw;
}

/* BUTTONS */
/* Generic */
.ButtonLarge{
  width: 100% !important;
  border-radius:3.2vw !important;
  padding: 1.6vw 9.87vw !important;
  height: 12.8vw !important;
}
.ButtonTeal{
  /* TODO: poppins semi bold 14 */
  background: linear-gradient(107.8deg, #4E88F8 -6.93%, #68F7EE 122.3%);
  border-width: 0 !important;
}
.ButtonFilter{
  border-radius:26.67vw !important;
  border-width: 0 !important;
  height:10.67vw !important;
  margin-right:2.67vw !important;
}
.ButtonFilterUnselected{
  background:#252541 !important;
}
.ButtonFilterSelected{
  background: rgba(121, 122, 242, 1) !important;
}
.ButtonClose{
  right: 4.27vw;
  top:5vw !important;
}
.ButtonBack{
  left: 4.27vw;
  top:5vw !important;
}
.ButtonFacebook{
  width:100vw;
  height:13vw;
}
.ButtonGeneric{
  padding: 0.2133vw;
  width:100%;
  height:100%;

  position: absolute;
  width: 11.73vw;
  height: 11.73vw;
  top: 20.27vw;

  background: rgba(255, 255, 255, 0.1);
  
  border-radius: 3.2vw;

  vertical-align: middle;
  text-align: center;
}

/* Menu */
.ButtonsTop{
  padding-right:4.67vw;
  padding-top:0vw;
  float:right;
}
.MenuTopButton{
  padding-left:4.67vw !important;
}

/* Social */
.SocialButton{

    display:inline-block;
    text-align: center;

    width: 100%;
    height: 13vw;
    border-radius: 26vw !important;
    background-color: transparent !important;
    border:1px solid white !important;
    margin-bottom:6.4vw;
}

/* Login */
.ButtonLoginEmail{

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1.6vw 9.87vw !important;
    gap: 2.67vw !important;

    width: 100%;
    height: 12.8vw;

    background: linear-gradient(105.1deg, #4E88F8 -2.37%, #68F7EE 109.69%) !important;
    border-radius: 6.4vw !important;

    align-self: stretch;

}

/* Profile */
.ButtonProfileFollow{
    position:relative !important;
    width: 91.48vw !important;
    min-height: 12.8vw !important;
    border-radius: 3.2vw !important;
    left:0 !important;
    /*top: 52vw;*/
}

/* User */
.FollowUserPseudo{
  padding-left:4.26vw;
}
.FollowUserAlreadyFollowed{
  color: #96E199 !important;
  position:absolute;
  right:4.26vw  ;
}
.ButtonFollow{

  position:absolute;
  
  right:4.26vw;
  width: 26.13vw;
  height: 8.53vw;

  background: linear-gradient(112.57deg, #4E88F8 12.09%, #68F7EE 115.96%);
  border-radius: 2.13vw;

}
.ButtonFollowSelected{
  background:transparent;
  border: 0.267vw solid #FFFFFF;
}
.ButtonSelect{
  padding-right:4.26vw;
  position:absolute;
  right:0;
}
.ButtonSelectImage{
  width:24px;
  height:24px;
}

/* CONTAINERS */
.container{
  /* override bootstrap */
  margin: 0 !important;
}
#root{
  background: #252541;
}
body{
  background: #252541;
}
.ModalViewBackground{
  backdrop-filter: blur(32px);
  position:fixed;
  
  width:100vw;
  opacity:0;
  transition: opacity 100ms ease-in-out;

  pointer-events: none !important;

  z-index: 2000;

}
.ModalViewBackgroundOpen{
  position:fixed;
  /*min-height:100vh;*/
  width:100vw;
  opacity:1;
  overflow-y: auto;
  pointer-events: auto !important;
}
.ModalViewFullScreen{
  transition: opacity 100ms ease-in-out !important;
  top: 0 !important;
}
.ModalViewInfo{
  background:linear-gradient(142.55deg, #F9F9F9 0%, #DDE8FE 101.42%) !important;
  width:91.67vw;
  border:0.5vw solid #19171D ;
  position:fixed;
  bottom:-40vw;
  left:4vw;
  opacity:0;
  
  border-radius: 3.2vw;

  transition: bottom 500ms ease-in-out, opacity 100ms ease-in-out;
  
}
.ModalViewInfoOpen{

  opacity:1;
  bottom:4vw;
  
}
.ModalViewInfoContent{
  vertical-align: middle;
  margin-left:4.1vw;
  margin-top:3.7vw;
  
}
.ModalViewInfoText{
  color: #19171D !important;
  
}
.ModalViewInfoImage{
  float:right;
  position:relative;
  right:3vw;
  top:-0.8vw;
}
.ModalView{
  
  transform: scale(1);
  
  position:fixed;
  width:100vw;
  opacity:0;
  left:0;
  top: 150vh; /* just needs to be large */
  transition: top 500ms ease-in-out, opacity 100ms ease-in-out;
  margin:auto !important;

}
.ModalViewOpen{
  position:absolute;
  opacity:1;
  top:8.52vw;
  
}
.ModalViewValidationClose{
  transition: top 500ms ease-in-out, opacity 100ms ease-in-out;
  opacity:0;
}
.ModalViewValidationOpen{
  opacity:1;
}
.ModalViewValidation{
  position:fixed;
  top:3.8vw; /* 40vw */
  left: 4vw;
  height: 14vw;/*40vw;*/
  background:linear-gradient(142.55deg, #F9F9F9 0%, #DDE8FE 101.42%) !important;
  border-radius: 3.2vw; /*4.2vw;*/
  border:0.0vw solid #19171D;
  vertical-align: middle;
  z-index:2000000;

  width:92vw;
}
.ModalViewValidationImage{
  margin-top:0.3vw;
  float: right;
  vertical-align: middle;
}
.ModalViewValidationImageCount1{
  width:40vw;
  left:30vw;
}
.ModalViewValidationImageCount2{
  width:65vw;
  left:17.5vw;
}
.ModalViewValidationImageCount3{
  width:92vw;
  left:4vw;
}
.ModalViewValidationTextContainer{
  float: left;
  position:absolute;
  padding-left:4vw;
  padding-top:1.5vw;
}
.ModalViewValidationSubtext{
  color:black;
}
.ModalViewValidationText{
  color:gray;
}
.BodyModalViewOpen{
  /*overflow:hidden;*/
}
.ContainerMain{

  background: #252541;
  z-index: -100;
  width: 100%!important;
  height: auto !important;
  position:absolute;
  overflow-x:hidden !important;
  overflow-y:scroll !important;
  
  margin:0;
  padding:0;
  /*padding-bottom:22.93vw !important; /* for menu bottom */

  /*min-height:100vh !important;*/

}
.ContainerLoading{
  background: #19171D;
  /*height:120vh !important; */
}
.ContainerMainBlue{
  background: url("./img/background/blue.svg");
  background-size: 120%;
}
.ContainerMainBlack{
  background: url("./img/background/black.png"); /* no-repeat center center fixed;*/
  background-size: 120%;
}
.ContainerModal{
  background: rgba(51, 51, 86, 1)
}

.ContainerH1{
  padding-left:4.26vw;
  padding-right:4.26vw;
  padding-top:6.4vw;
  padding-bottom:6.4vw;
}
.noPaddingLeft{
  padding-left:0;
}
.ContainerH2{
  padding-bottom:3.2vw;
}
.ContainerContent{
  padding-left: 4.26vw !important;
  padding-right: 4.26vw !important;
}
.ContainerContentNoH1{
  padding-top:8.52vw !important;
}
.ContainerInfo{
  font-size:5vw !important;
}
.ContainerLink{
  border: 0.2667vw solid rgba(255, 255, 255, 0.1);
  border-radius: 3.2vw;
  padding:4.2vw;
}

/* Users */
.ContainerUserCellRow{
  width:91.67vw;
}
.ContainerUserCellVignette{
  width:30vw;
}

/* Words */
.ContainerWord{

  display:inline-flex !important;
  align-items: center;
  flex-basis:auto !important;

  margin: 0 !important;
  
  border-radius: 26.67vw !important;
  font-size:3.73vw !important;

  box-shadow: 0vw 1.07vw 1.07vw 0vw rgba(0, 0, 0, 0.25);

  margin-right:2.67vw !important;
  margin-bottom:2.67vw !important;

  position:relative;

  /*
  padding: 2.01vw 3vw 2.01vw 3vw !important;  2.01vw with borders. 2.54vw without 
  */

}

.WordCellUnselected{
  background: #3A3A5F !important;
  /*border:0 !important;*/
  /*border: 0.53vw solid #252541;*/
  border: 0.53vw solid #DADADA !important;
}
.WordCellCreated{
  background: linear-gradient(107.8deg, #4E88F8 -6.93%, #68F7EE 122.3%), #3A3A5F !important;
  border: 0.53vw solid #4E88F8 !important;
}
.WordCellSelected{
  background: linear-gradient(267.06deg, #735FF3 7.88%, #539AF6 97.33%) !important;
  border: 0.53vw solid #706DFF !important;
}
.WordCellSwipe{
  backdrop-filter: blur(24px);
  /*background: linear-gradient(180deg, #F9F9F9 0%, #DDE8FE 100%);*/
  /*border: 0.53vw solid #706DFF !important;*/
  color:black !important;
  box-shadow: 0vw 0vw 0vw 0vw!important;
}
.WordCellSwipeBelow{
  opacity:0.4;
  background:white;
  position:absolute;
}
.WordCellWithCount{
  /*padding-right:12.67vw !important;*/
}
.WordCellText{
  /*display:inline-block;*/
  /*vertical-align: middle;*/
  margin-top:2.01vw;
  margin-bottom:2.01vw;
  margin-left:3vw;
  margin-right:3vw;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 60vw;
}
/*
.WordCellCount{
  display:inline-block;
  border-radius: 26.67vw;
  width:9.4vw;
  height:9.4vw;
  line-height:9.4vw;
  margin-top:-1.9vw;
  margin-left:3.3vw;
  
  vertical-align: middle;
  position:absolute;
  text-align:center;

}
*/

.WordCellCount{

  border-radius: 26.67vw;
  width:9.4vw;
  height:9.4vw;
  float:right;
  position:relative;
  margin-right: 0;
  display:inline-flex;
  align-items: center;
  justify-content: center;
}

.WordCellCountUnselected{
  background: rgba(255, 255, 255, 0.1);
}
.WordCellCountSelected{
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(24px);
}
.WordCellCountCreated{
  /* background: #60D3F1; */
  backdrop-filter: blur(24px);
}

/* Login */
.ContainerLoginBottom{
  padding-top:5vw;
  padding-right:4.26vw;
  padding-left:4.26vw;

}

/* Profile */
.ContainerProfile{

    position:relative !important;
    margin:0 !important;
    padding:0 !important;
    
    top: 9.14vw;
    padding-left: 4.26vw !important;
    padding-right: 4.26vw !important;
    /*width: 91.48vw !important;*/
    
}
.ContainerProfileUser{

    position:relative !important;
    
    padding-left: 6.57vw !important;
    padding-right: 6.57vw !important;

    margin:0 !important;
    padding:0 !important;

}
.ContainerProfileAvatar{
    /* Picture */
    position:relative !important;

    height: 23.56vw;
    text-align:center;
    padding-left: 1.5vw; /* looks more centered */
    padding-bottom: 4vw;

}
.ContainerProfileUserName{
    /* Name */

    position:relative !important;
    padding: 0px;
    text-align: center;
}
.ContainerProfileStats{
    position:relative !important;
    
    padding-top:5vw;
    padding-bottom:5vw;
    text-align: center;
    /*top: 32vw !important;*/
    
}
.ContainerProfileStatsCell{
    background-color: transparent;
    width:25% !important;
    text-align:center;
    display:inline-block;
}
.ContainerProfileStatsCellNumber{
    font-size:1.3em !important;
    text-align:center;
}
.ContainerProfileActivity{
  padding-left: 4.26vw !important;
  padding-right: 4.26vw !important;
}

/* Topic */
.ContainerTopicCell{
  width:91.47vw;
  margin-bottom:4.265vw;
  overflow:hidden;
  border-radius: 3.2vw;
}
.ContainerTopicCellImage{
    
    border-radius: 3.2vw;
    width: 100%;
    height: 51.73vw;
    
}
.TopicInText{
  color: #96E199 !important;
}
.ContainerTopicImdb{
  
  width: 43.73vw;
  height: 9.067vw;

  background: rgba(255, 255, 255, 0.1);
  border-radius: 12.035px;
}
.ContainerTopicImdbScore{
  font-size:4.267vw !important;
  padding-left:8vw;
  position:relative;
  top:-0.4vw;
}
/* Activity */
.ContainerActivity{

    padding-bottom: 7vw;

}
.ContainerActivityPurple{
  /*
    background-color:#8777EF;
    opacity:0.3;*/
}
.ContainerActivityPseudo{
    padding-bottom: 2vw;
}
.ContainerActivityImage{
  width:78.13vw !important;
  height:48vw !important;
  border-radius:3.2vw !important;
  overflow:hidden !important;
}

/* Social */
.ContainerSocialButtons{

    margin-bottom:6.4vw;

}

/* Swipe */
.SwipeDeck{
  
  position:relative;
  will-change: transform;

  /*width:45.57vh;*/
  max-width:91.47vw;
  margin: auto;

}
.SwipeCardContainer{
  position: absolute;
  margin:auto;
}
.SwipeCard {

  position:relative;
  touch-action: none;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center center;

  will-change: transform;
  

}
.SwipeCardBehind{
  
  /*width:37.47vh !important;
  max-width:88.47vw !important;
  */
  
  /*width:42.47vh !important;*/
  /*margin-left:1.3vh !important;*/
  position:absolute;
  top: -1.27vh !important;
  max-width:88.47vw !important;
  z-index: -1 !important;


}

.SwipeCardBehindContainer{
  width:100%;
  position:absolute;
  top: -2.67vw !important;
  max-width:80vw !important;
  z-index: -1 !important;
  align-items: center;
  display:flex;
  flex-direction: column;
}
.SwipeCardBehind{

  width:87%;
  position:absolute;
  filter:brightness(80%) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

}
.SwipeCardContent{

    position: absolute !important;

    left: 6.4vw !important;
    top: 8.53vw !important;
    max-width: 77vw !important;

    pointer-events:none;

}
.SwipeCardContentTitle{
    font-size:5.8vw !important;
    font-family: 'Montserrat';
    font-weight:600;
    padding-bottom:2vw;

    pointer-events:none;

    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.SwipeCardNotificationTitle{
  font-size:4.5vw !important;
  font-family: 'Montserrat';
  pointer-events:none;

    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.SwipeCardContentWord{
    /*position: absolute;*/
    /*right:0vw;*/
    pointer-events:none;
}
.SwipeCardContentAvatar{
    display:block;
    padding-top:2vw;
    position:relative;

    pointer-events:none;
}
.SwipeCardContentAvatarText{
  bottom:6.5vw;
  left:17vw;
  position:absolute;
  width:70vw;

  pointer-events:none;
}
.SwipeCardNotificationContent{

  position: absolute !important;

  left: 6.4vw !important;
  top: 8.53vw !important;
  max-width: 77vw !important;

  pointer-events:none;

}
.SwipeCardNotificationImageContainer{

  align-items: center;
  justify-content: center;
  width: 85%;

}
.SwipeCardNotificationImage{
  overflow: hidden;
  border-radius: 2.8vw !important;
}
.SwipeCardWord{
  position: relative !important;
  padding: 0px !important;
  margin: 0px !important;
  left: 6.4vw;
  top: 17.6vw;
  max-width: 100% !important;

  pointer-events:none;
  
}

.SwipeIconsLargeContainer{
  position: absolute !important;
  z-index: 1000 !important;
}
.SwipeIconsContainer{
  position:relative;
  margin:auto;
  max-width:80vw !important;
  /*
  width:34.524vh;
  height: 8.2848vh;
  top:66vh;
  left:7.4vh; 
  */
  
}
.SwipeIconsContainerStyle{
  border-radius: 20.8vw !important;

  /*
  border: 0.8vw solid #706DFF !important;
  background: linear-gradient(180deg, #F9F9F9 0%, #DDE8FE 100%);
  */
  
  background: linear-gradient(120deg, #FFFFFF 0%, #706DFF 250%);
  /*
  background: linear-gradient(180deg, #FFFFFF 0%, #F8FAFF 100%);
  */
}
.SwipeIconsContainerSelected{
  /*background: linear-gradient(120deg, #FFFFFF 0%, #706DFF 50%);*/
  background: #706DFF !important;
}
.SwipeIcon{
  width: 100%;
  height: 100%;
}
.SwipeIconContainer{

  position: relative;
  height: 100%;
  display: inline-block;  
  
}
.SwipeIconContainerCenter{
    /*top:-2.3vw !important; no border */
    top:-1.9vw !important;
    left:2.2vw
}
.SwipeIconContainerLeft{
    left:1.2vw;
    top:-0.8vw;
}
.SwipeIconContainerRight{
  right:1.2vw;
  top:0.5vw;
}
.SwipeIconContainerCircle{
  /*background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(11.2vw);*/
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 50%;
  height: 75%;
  width: 75%;

  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(11.2vw);
  opacity: 0.6;
  
}

.SwipeButton{

  position:absolute;
  height:30vw;
  width:30vw;

  background: rgba(255, 255, 255, 0.24);
  backdrop-filter: blur(32px);
  border-radius: 100px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: background 100ms ease-in-out;

  pointer-events: none;

}
.SwipeButtonSelected{
  background: linear-gradient(161.72deg, #253BFF 0.31%, #3345FE 0.32%, #EDD4F5 136.21%) !important;
}
.SwipeDarkScreen{
  position:absolute;
  top:0;
  
  height:100%;
  width:100%;
  z-index:10000;
  backdrop-filter: blur(32px);

  pointer-events: none;
  transition: opacity 500ms ease-in-out;
}
.SwipeButtonRight{
  right: 4vw;
  /*top:35vh;*/
}
.SwipeButtonLeft{
  left: 4vw;
  /*top:35vh;*/
}
.SwipeButtonTop{
  top: 4vw;
  left:35vw;
}
.SwipeButtonBottom{
  /*bottom: 25vh;*/
  left:35vw;
}

.SwipeButtonImage{
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.SwipeButtonImageTiny{
  width:50%;
  height:50%;
}
.SwipeButtonImageSmall{
  width:70%;
  height:70%;
}
.SwipeButtonImageBig{
  width:100%;
  height:100%;
}
.SwipeButtonImageRight{
  top:1vh;
  left:-0.5vh;
  position:absolute;
}

/* INPUTS */
.Input{

    margin:0vw;
    width: 84vw;
    height: 13.87vw;
    margin-bottom: 10vw;
    background: rgba(255, 255, 255, 0.1);
    border-bottom: 0.267vw solid rgba(255, 255, 255, 0.4);
    border-radius: 0.533vw;
    padding-left: 5vw;

}
.SearchBar{
  background: rgba(255, 255, 255, 0.04);
  border-radius: 3.2vw;
  width: 100%;
  height: 11.73vw;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}
.SearchBarIcon {
  flex: 0;
  padding: 0.5rem;
  opacity: 0.4;
}
.SearchBarIconSelected{
  flex: 0;
  padding: 0.5rem;
  opacity: 1.;
}
.SearchBarBox {
  border: 0;
  padding: 0.5rem 0.5rem 0.5rem 0;
  background:transparent;
  flex: 1;
}
.SearchBarX {
  background:rgba(255, 255, 255, 0.12);
  padding: 0;
  opacity: 0.4;
  
  height:9.067vw;
  width:9.067vw;
  border-radius:2.13vw;
  top:1.3vw;
  right:1.3vw;
  position:absolute;
}
.SearchBarXIcon{
  
  width: 3.02vw;
  height: 3.02vw;
  padding:3vw;
  
  /*
  width: 4.02vw;
  height: 4.02vw;
  padding:2.6vw;
  */
}
.ViewSearchBar{
  width:75vw;
  float:right;
}

/* Autocomplete */
.AutocompleteCell{
  border-radius:3.2vw;
  width: 100vw;
  height: 19.2vw;
  display:inline-block;
  vertical-align: middle;
  margin-bottom:4.2vw;
}
.AutocompleteCellImage{
  border-radius:3.2vw;
  width: 36.26vw;
  height: 19.2vw;
  overflow: hidden;
  margin-right: '2.3vw';
  display:inline-block;
  vertical-align: middle;
}
.AutocompleteText{
  width:52vw;
  vertical-align: middle;
  display:inline-block;
  margin-left:4.2vw;
}

/* Word Create */
.WordCreateLogo{

  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);

  top:4.26vw !important;
  z-index:5;
  
}
.WordCreateContainer{

  padding: 4.62vw;
  padding-top:21vw;
  margin-top:20.92vw !important;
  width:82vw;
  height:60vh;
  position: relative;

  background: rgba(51, 51, 86, 0.3);
  border-radius: 5.33vw;-webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.WordCreateTitle{
  font-size:4.67vw !important;
  margin-bottom: 2.2vw;
}
.WordCreateInput{
  background: linear-gradient(107.8deg, #4E88F8 -6.93%, #68F7EE 122.3%), #3A3A5F !important;
  border: 0.53vw solid #4E88F8 !important;
  border-radius:5.33vw;
  height:8.2vw;
  width:97%;
  padding-left:4.2vw;
  
}

/* Settings */
.SettingsCell{
  height: 12.4vw;
  width:100%;
  padding-top:6.2vw;
  
}
.SettingsCellBorder{
  border-bottom: rgba(255, 255, 255, .2) solid 0.26vw;
}

/* Empty */
.Empty{
  
  text-align:center;
  margin:auto;
  margin-top:10vw;
  width:35vw;
  display:block;
}

/*
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/